'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ownerWindow;

var _ownerDocument = require(113);

var _ownerDocument2 = _interopRequireDefault(_ownerDocument);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownerWindow(node) {
  var doc = (0, _ownerDocument2.default)(node);
  return doc && doc.defaultView || doc.parentWindow;
}
module.exports = exports['default'];