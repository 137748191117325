'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require(560);

var _react2 = _interopRequireDefault(_react);

var _Portal = require(512);

var _Portal2 = _interopRequireDefault(_Portal);

var _Position = require(513);

var _Position2 = _interopRequireDefault(_Position);

var _RootCloseWrapper = require(514);

var _RootCloseWrapper2 = _interopRequireDefault(_RootCloseWrapper);

var _elementType = require(527);

var _elementType2 = _interopRequireDefault(_elementType);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Built on top of `<Position/>` and `<Portal/>`, the overlay component is great for custom tooltip overlays.
 */
var Overlay = function (_React$Component) {
  _inherits(Overlay, _React$Component);

  function Overlay(props, context) {
    _classCallCheck(this, Overlay);

    var _this = _possibleConstructorReturn(this, (Overlay.__proto__ || Object.getPrototypeOf(Overlay)).call(this, props, context));

    _this.state = { exited: !props.show };
    _this.onHiddenListener = _this.handleHidden.bind(_this);
    return _this;
  }

  _createClass(Overlay, [{
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (nextProps.show) {
        this.setState({ exited: false });
      } else if (!nextProps.transition) {
        // Otherwise let handleHidden take care of marking exited.
        this.setState({ exited: true });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          container = _props.container,
          containerPadding = _props.containerPadding,
          target = _props.target,
          placement = _props.placement,
          shouldUpdatePosition = _props.shouldUpdatePosition,
          rootClose = _props.rootClose,
          children = _props.children,
          Transition = _props.transition,
          props = _objectWithoutProperties(_props, ['container', 'containerPadding', 'target', 'placement', 'shouldUpdatePosition', 'rootClose', 'children', 'transition']);

      // Don't un-render the overlay while it's transitioning out.


      var mountOverlay = props.show || Transition && !this.state.exited;
      if (!mountOverlay) {
        // Don't bother showing anything if we don't have to.
        return null;
      }

      var child = children;

      // Position is be inner-most because it adds inline styles into the child,
      // which the other wrappers don't forward correctly.
      child = _react2.default.createElement(
        _Position2.default,
        { container: container, containerPadding: containerPadding, target: target, placement: placement, shouldUpdatePosition: shouldUpdatePosition },
        child
      );

      if (Transition) {
        var onExit = props.onExit,
            onExiting = props.onExiting,
            onEnter = props.onEnter,
            onEntering = props.onEntering,
            onEntered = props.onEntered;

        // This animates the child node by injecting props, so it must precede
        // anything that adds a wrapping div.

        child = _react2.default.createElement(
          Transition,
          {
            'in': props.show,
            transitionAppear: true,
            onExit: onExit,
            onExiting: onExiting,
            onExited: this.onHiddenListener,
            onEnter: onEnter,
            onEntering: onEntering,
            onEntered: onEntered
          },
          child
        );
      }

      // This goes after everything else because it adds a wrapping div.
      if (rootClose) {
        child = _react2.default.createElement(
          _RootCloseWrapper2.default,
          { onRootClose: props.onHide },
          child
        );
      }

      return _react2.default.createElement(
        _Portal2.default,
        { container: container },
        child
      );
    }
  }, {
    key: 'handleHidden',
    value: function handleHidden() {
      this.setState({ exited: true });

      if (this.props.onExited) {
        var _props2;

        (_props2 = this.props).onExited.apply(_props2, arguments);
      }
    }
  }]);

  return Overlay;
}(_react2.default.Component);

Overlay.propTypes = _extends({}, _Portal2.default.propTypes, _Position2.default.propTypes, {

  /**
   * Set the visibility of the Overlay
   */
  show: _react2.default.PropTypes.bool,

  /**
   * Specify whether the overlay should trigger `onHide` when the user clicks outside the overlay
   */
  rootClose: _react2.default.PropTypes.bool,

  /**
   * A Callback fired by the Overlay when it wishes to be hidden.
   *
   * __required__ when `rootClose` is `true`.
   *
   * @type func
   */
  onHide: function onHide(props) {
    var propType = _react2.default.PropTypes.func;
    if (props.rootClose) {
      propType = propType.isRequired;
    }

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return propType.apply(undefined, [props].concat(args));
  },


  /**
   * A `<Transition/>` component used to animate the overlay changes visibility.
   */
  transition: _elementType2.default,

  /**
   * Callback fired before the Overlay transitions in
   */
  onEnter: _react2.default.PropTypes.func,

  /**
   * Callback fired as the Overlay begins to transition in
   */
  onEntering: _react2.default.PropTypes.func,

  /**
   * Callback fired after the Overlay finishes transitioning in
   */
  onEntered: _react2.default.PropTypes.func,

  /**
   * Callback fired right before the Overlay transitions out
   */
  onExit: _react2.default.PropTypes.func,

  /**
   * Callback fired as the Overlay begins to transition out
   */
  onExiting: _react2.default.PropTypes.func,

  /**
   * Callback fired after the Overlay finishes transitioning out
   */
  onExited: _react2.default.PropTypes.func
});

exports.default = Overlay;
module.exports = exports['default'];