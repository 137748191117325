// @flow

import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

type Props = {
    close: () => void,
    show: boolean,
};

class NagModal extends React.Component {
    props: Props;

    shouldComponentUpdate(nextProps: Props) {
        return this.props.show !== nextProps.show;
    }

    render() {
        const {close, show} = this.props;

        return <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Thank You!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
				<p> Thank you for playing Grizzled GM MOBA Free. If you aren't aware, there is a subreddit /r/GrizzledGM that users share files and report bugs at. </p>
				<p> Feel free to e-mail commissioner@grizzledgm.com if you have questions as well. </p>
            </Modal.Body>
        </Modal>;
    }
}

NagModal.propTypes = {
    close: React.PropTypes.func.isRequired,
    show: React.PropTypes.bool.isRequired,
};

export default NagModal;
