'use strict';

exports.__esModule = true;

var _extends2 = require(10);

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require(12);

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _classCallCheck2 = require(9);

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require(13);

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require(11);

var _inherits3 = _interopRequireDefault(_inherits2);

var _classnames = require(101);

var _classnames2 = _interopRequireDefault(_classnames);

var _react = require(560);

var _react2 = _interopRequireDefault(_react);

var _elementType = require(527);

var _elementType2 = _interopRequireDefault(_elementType);

var _bootstrapUtils = require(376);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var propTypes = {
  componentClass: _elementType2['default']
};

var defaultProps = {
  componentClass: 'div'
};

var ModalBody = function (_React$Component) {
  (0, _inherits3['default'])(ModalBody, _React$Component);

  function ModalBody() {
    (0, _classCallCheck3['default'])(this, ModalBody);
    return (0, _possibleConstructorReturn3['default'])(this, _React$Component.apply(this, arguments));
  }

  ModalBody.prototype.render = function render() {
    var _props = this.props,
        Component = _props.componentClass,
        className = _props.className,
        props = (0, _objectWithoutProperties3['default'])(_props, ['componentClass', 'className']);

    var _splitBsProps = (0, _bootstrapUtils.splitBsProps)(props),
        bsProps = _splitBsProps[0],
        elementProps = _splitBsProps[1];

    var classes = (0, _bootstrapUtils.getClassSet)(bsProps);

    return _react2['default'].createElement(Component, (0, _extends3['default'])({}, elementProps, {
      className: (0, _classnames2['default'])(className, classes)
    }));
  };

  return ModalBody;
}(_react2['default'].Component);

ModalBody.propTypes = propTypes;
ModalBody.defaultProps = defaultProps;

exports['default'] = (0, _bootstrapUtils.bsClass)('modal-body', ModalBody);
module.exports = exports['default'];