'use strict';

exports.__esModule = true;

var _extends2 = require(10);

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require(12);

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _classCallCheck2 = require(9);

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require(13);

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require(11);

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require(560);

var _react2 = _interopRequireDefault(_react);

var _Collapse = require(347);

var _Collapse2 = _interopRequireDefault(_Collapse);

var _bootstrapUtils = require(376);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var contextTypes = {
  $bs_navbar: _react.PropTypes.shape({
    bsClass: _react.PropTypes.string,
    expanded: _react.PropTypes.bool
  })
};

var NavbarCollapse = function (_React$Component) {
  (0, _inherits3['default'])(NavbarCollapse, _React$Component);

  function NavbarCollapse() {
    (0, _classCallCheck3['default'])(this, NavbarCollapse);
    return (0, _possibleConstructorReturn3['default'])(this, _React$Component.apply(this, arguments));
  }

  NavbarCollapse.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        props = (0, _objectWithoutProperties3['default'])(_props, ['children']);

    var navbarProps = this.context.$bs_navbar || { bsClass: 'navbar' };

    var bsClassName = (0, _bootstrapUtils.prefix)(navbarProps, 'collapse');

    return _react2['default'].createElement(
      _Collapse2['default'],
      (0, _extends3['default'])({ 'in': navbarProps.expanded }, props),
      _react2['default'].createElement(
        'div',
        { className: bsClassName },
        children
      )
    );
  };

  return NavbarCollapse;
}(_react2['default'].Component);

NavbarCollapse.contextTypes = contextTypes;

exports['default'] = NavbarCollapse;
module.exports = exports['default'];