'use strict';

var define = require(102);

var implementation = require(332);
var getPolyfill = require(334);
var shim = require(335);

var polyfill = getPolyfill();

define(polyfill, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = polyfill;
